import { template as template_3f85c6550b87451a8a5b890ea52af9d4 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_3f85c6550b87451a8a5b890ea52af9d4(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
