import { template as template_5d49f732375f42c4b99bdbcf8eb592b7 } from "@ember/template-compiler";
const WelcomeHeader = template_5d49f732375f42c4b99bdbcf8eb592b7(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
