import { template as template_ac37bf23d06942e9991b9185564b0085 } from "@ember/template-compiler";
const FKControlMenuContainer = template_ac37bf23d06942e9991b9185564b0085(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
